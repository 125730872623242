import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67b9ab0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stars" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.of, (n, index) => {
        return (_openBlock(), _createElementBlock("i", {
          key: index,
          class: "fa fa-star"
        }))
      }), 128))
    ]),
    _createElementVNode("div", {
      style: _normalizeStyle(_ctx.width)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.of, (n, index) => {
        return (_openBlock(), _createElementBlock("i", {
          key: index,
          class: "fa fa-star"
        }))
      }), 128))
    ], 4)
  ]))
}