
import { defineComponent } from 'vue';
import ShoeComponent from '@/components/Shoe.vue';
import { Color, colors, Shoe, Tag } from './Edit.vue';
import {api} from '@/utils';

export default defineComponent({
   name: 'Overview',
   components: {
      ShoeComponent
   },
   data: () => ({
      shoes: [] as Shoe[],
      grid: true,
      showSearch: false,
      apiUrl: process.env.VUE_APP_API_URL,
      tag: null as string | null,
      tags: [] as Tag[],
      token: false as boolean,
      colors: colors as Color[],
      tab: null as number | null,
      owner: localStorage.getItem('owner') || 'v.wicht01@gmail.com'
   }),
   methods: {
      addTag() {
         if (!this.tag) {
            return;
         }

         if (typeof this.tags === 'undefined') {
            this.tags = [];
         }
         this.tags.push({ name: this.tag });
         this.tag = null;
      },

      removeTag(index: number) {
         this.tags.splice(index, 1);
      },

      async search() {
         this.shoes = [];
         let tags = '';
         if (this.tags.length) {
            tags = '?search=';
            tags += this.tags.map(t => t.name).join(',');
         }
         this.shoes = (await api(`${this.apiUrl}/shoe${tags}`)) as Shoe[];
         /* for (let i = 0; i < 20; ++i) {
            this.shoes.push(r[1]);
         } */
      },

      switchOwner() {
         this.owner = this.owner === 'nd1312@gmail.com' ? 'v.wicht01@gmail.com' : 'nd1312@gmail.com';
         localStorage.setItem('owner', this.owner);
      },

      tagCount(type: 'color' | 'height' | 'style'): string {
         let searchNames: string[] = [];
         if (type === 'color') {
            searchNames = this.colors.map(c => c.name);
         } else if (type === 'height') {
            searchNames = ['Flach', 'Hoch'];
         } else if (type === 'style') {
            searchNames = ['Offen', 'Geschlossen'];
         }
         const count = this.tags.filter(t => searchNames.includes(t.name)).length;
         return count ? `(${count}) ` : '';
      }
   },
   computed: {
      filteredShoes(): Shoe[] {
         return this.shoes.filter(s => {
            const notFound = [];

            if (s.owner !== this.owner) {
               return false;
            }

            for (const searchTag of this.tags) {
               if (
                  !s.tags.filter(
                     shoeTag =>
                        shoeTag.name.toLowerCase() ===
                        searchTag.name.toLowerCase()
                  ).length
               ) {
                  notFound.push(searchTag);
               }
            }

            if (notFound.length === 0) {
               return true;
            }

            return false;
         });
      },
      unfilteredCount(): number {
         return this.shoes.filter(s => s.owner === this.owner).length;
      },
      shoeCount(): string {
         let strings = [];
         if (this.unfilteredCount !== this.filteredShoes.length) {
            strings.push(this.filteredShoes.length, 'von');
         }
         strings.push(this.unfilteredCount);
         let shoeString = 'Schuh';
         if (this.unfilteredCount > 1) {
            shoeString += 'e';

            if (this.unfilteredCount !== this.filteredShoes.length) {
               shoeString += 'n';
            }
         }
         strings.push(shoeString);
         return strings.join(' ');
      },
      overallPrice(): { price: string, paid: string } {
         if (!this.shoes || this.shoes.length === 0) {
            return {price: '', paid: ''};
         }

         return {
            price: Intl.NumberFormat('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(this.shoes.filter(s => s.owner === this.owner).map(s => s.price).reduce((prev, current) => prev + current)),
            paid:  Intl.NumberFormat('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(this.shoes.filter(s => s.owner === this.owner).map(s => s.paid).reduce((prev, current) => prev + current))
         }
      }
   },
   mounted: async function() {
      if (localStorage.getItem('token')) {
         this.token = true;
         this.search();
      }

      document.body.addEventListener('gapi', () => {
         this.token = true;
         this.search();
      });
   }
});
