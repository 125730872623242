import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-grow-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_slider = _resolveComponent("slider")!
  const _component_stars = _resolveComponent("stars")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open(_ctx.shoe.id))),
    class: "d-flex flex-column h-100"
  }, [
    _createVNode(_component_slider, {
      images: _ctx.shoe.images.map(i => `${_ctx.apiUrl}/shoe/${_ctx.shoe.id}/image/${i.id}/${_ctx.wide ? '1200' : '600'}`)
    }, null, 8, ["images"]),
    _createElementVNode("strong", _hoisted_1, _toDisplayString(_ctx.shoe.brand) + " " + _toDisplayString(_ctx.shoe.name), 1),
    _createElementVNode("div", null, [
      _createVNode(_component_stars, {
        rating: _ctx.shoe.rating,
        of: 5
      }, null, 8, ["rating"])
    ])
  ]))
}