import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

createApp(App).use(router).mount('#app');

// @ts-ignore
window.gapiLoaded = function() {
   gapi.signin2.render('my-signin2', {
      scope: 'profile email',
      width: 240,
      height: 50,
      longtitle: true,
      theme: 'dark',
      onsuccess: (googleUser: any) => {
         var id_token = googleUser.getAuthResponse().id_token;
         localStorage.setItem('token', id_token);
         document.body.dispatchEvent(new Event('gapi'));
      }
   });
}