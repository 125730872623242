
import router from '@/router';
import { api } from '@/utils';
import { defineComponent } from 'vue';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
const notyf = new Notyf({
   duration: 1000,
});

interface FileEntry {
   file: File;
   thumbData: string;
}

interface Image {
   id: number;
}

export interface Tag {
   id?: number;
   name: string;
}

export interface Shoe {
   id: number;
   brand: string;
   name: string;
   images: Image[];
   tags: Tag[];
   owner: string;
   shelf_position: number,
   shelf_row: number,
   price: number,
   paid: number
}

export interface Color {
   name: string,
   hex: string,
   id?: number
}

interface Shelf {
   id: number,
   name: string
}

export let colors: Color[] = [
   {
      name: 'Beige',
      hex: '#e0d0c8'
   },
   {
      name: 'Blau',
      hex: '#284573'
   },
   {
      name: 'Braun',
      hex: '#996329'
   },
   {
      name: 'Camouflage',
      hex: '#30631a'
   },
   {
      name: 'Gelb',
      hex: '#f0ec1f'
   },
   {
      name: 'Gold',
      hex: '#edbc0c'
   },
   {
      name: 'Grau',
      hex: '#949494'
   },
   {
      name: 'Grün',
      hex: '#51ab1d'
   },
   {
      name: 'Leo',
      hex: '#c49a25'
   },
   {
      name: 'Lila',
      hex: '#9a2eb0'
   },
   {
      name: 'Orange',
      hex: '#ff8614'
   },
   {
      name: 'Pink',
      hex: '#ff2be6'
   },
   {
      name: 'Rosa',
      hex: '#faa5f0'
   },
   {
      name: 'Rosegold',
      hex: '#bf774d'
   },
   {
      name: 'Rot',
      hex: '#d41717'
   },
   {
      name: 'Schlangenoptik',
      hex: '#1a3d18'
   },
   {
      name: 'Schwarz',
      hex: '#000000'
   },
   {
      name: 'Silber',
      hex: '#acb9bf'
   },
   {
      name: 'Weiß',
      hex: '#ffffff'
   }
];

export default defineComponent({
   name: 'Overview',
   data: () => ({
      shoe: {
         tags: [{name: 'Flach'}, {name: 'Geschlossen'}] as Tag[],
         shelf_position: 1,
         shelf_row: 1,
         owner: 'v.wicht01@gmail.com'
      } as Shoe,
      shelfs: [] as Shelf[],
      files: [] as FileEntry[],
      fileInput: null as HTMLInputElement | null,
      dragging: -1 as number,
      tag: null as string | null,
      apiUrl: process.env.VUE_APP_API_URL,
      colors: colors as Color[],
      selects: {
         style: '',
         height: ''
      },
      owners: [
         {
            name: 'Schatziii',
            email: 'v.wicht01@gmail.com'
         },
         {
            name: 'Andi',
            email: 'nd1312@gmail.com'
         }
      ]
   }),
   methods: {
      changeSelect() {
         const selects: string[] = Object.values(this.selects);
         this.shoe.tags = this.shoe.tags.filter(t => !['Flach', 'Hoch', 'Offen', 'Geschlossen'].includes(t.name))
         selects.filter(n => n !== '').forEach(name => {
            this.shoe.tags.push({name})
         });
      },
      async save() {
         let url = `${this.apiUrl}/shoe`;
         if (!this.add) {
            url += `/${this.shoe.id}`;
         }

         const responseData: Shoe = await api(url, this.add ? 'POST' : 'PATCH', this.shoe);

         if (this.files.length === 0) {
            notyf.success('gespeichert');

            if (this.add) {
               router.replace(`/edit/${responseData.id}`);
            }
            return;
         }

         const formData = new FormData();
         for (const file of this.files) {
            formData.append('files[]', file.file, file.file.name);
         }

         this.shoe = await api(`${this.apiUrl}/shoe/${responseData.id}/image`, 'POST', formData);
         notyf.success('gespeichert');

         this.files = [];

         if (this.add) {
            router.replace(`/edit/${this.shoe.id}`);
         }
      },
      async fileSelected(event: Event) {
         const el = event.target as HTMLInputElement;
         if (el.files === null) {
            return;
         }

         this.files = [];
         for (const file of Array.from(el.files)) {
            this.files.push({
               file,
               thumbData: await this.readDataUrl(file)
            });
         }
      },
      readDataUrl(file: File): Promise<string> {
         return new Promise(resolve => {
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
               resolve(e.target!.result as string);
            };
            reader.readAsDataURL(file);
         });
      },

      async deleteImage(id: number) {
         this.shoe = await api(`${this.apiUrl}/shoe/${this.shoe.id}/image/${id}`, 'DELETE');
      },

      deleteUpload(index: number): void {
         this.files.splice(index, 1);
      },

      addFiles() {
         if (this.fileInput === null) {
            return;
         }
         this.fileInput.click();
      },

      addTag() {
         if (!this.tag) {
            return;
         }

         if (typeof this.shoe.tags === 'undefined') {
            this.shoe.tags = [];
         }
         this.shoe.tags.push({ name: this.tag });
         this.tag = null;
      },

      removeTag(index: number) {
         this.shoe.tags.splice(index, 1);
      },

      async dropped(i: number) {
         if (i === this.dragging) {
            this.dragging = -1;
            return;
         }

         const moveImage = this.shoe.images[this.dragging];
         this.shoe.images.splice(this.dragging, 1);
         this.dragging = -1;
         this.shoe.images.splice(i, 0, moveImage);

         this.shoe = await api(`${this.apiUrl}/shoe/${this.shoe.id}/image/sort`, 'PATCH', this.shoe.images);
      },

      async move(left: boolean, index: number) {
         const image = this.shoe.images.splice(index, 1)[0];
         this.shoe.images.splice(index + (left ? -1 : 1), 0, image);
         this.shoe = await api(`${this.apiUrl}/shoe/${this.shoe.id}/image/sort`, 'PATCH', this.shoe.images);
      }
   },
   computed: {
      add(): boolean {
         return this.$route.name === 'add';
      },
      colorTags(): any[] {
         let colorIds: {[key: string]: number} = {};
         if (typeof this.shoe.tags !== 'undefined') {
            this.shoe.tags.forEach(tag => {
               if (typeof tag.id === 'undefined') {
                  return;
               }
               colorIds[tag.name] = tag.id;
            });
         }
         return this.colors.map(color => {
            let nColor: {[key:string]: any} = {
               data: {
                  name: color.name
               },
               hex: color.hex
            };

            if (colorIds[color.name]) {
               nColor.data.id = colorIds[color.name];
            }
            return nColor;
         })
      }
   },
   mounted: async function() {
      this.shelfs = await api(`${this.apiUrl}/shelf`);
      if (!this.add) {
         this.shoe = await api(`${this.apiUrl}/shoe/${this.$route.params.id}`);
         this.shoe.tags.forEach(tag => {
            if (['Flach', 'Hoch'].includes(tag.name)) {
               this.selects.height = tag.name;
            }
            if (['Offen', 'Geschlossen'].includes(tag.name)) {
               this.selects.style = tag.name;
            }
         });
      }
   }
});
