
import { defineComponent } from 'vue';
export default defineComponent({
   props: {
      rating: Number as () => number,
      of: Number as () => number
   },
   computed: {
      width(): any {
         return { width: `${(this.rating ?? 0) / (this.of ?? 0) * 100}%` };
      }
   }
});
