
import { defineComponent } from 'vue';

export default defineComponent({
   name: 'Slider',
   data: () => ({
      slide: 1 as number,
      startX: -1 as number,
      startY: -1 as number,
      dragDistance: 0 as number,
      lastX: -1 as number,
      lastY: -1 as number,
      speed: 0 as number,
      dragging: false,
      slideElement: null as HTMLDivElement | null
   }),
   props: {
      images: Array as () => string[]
   },

   methods: {
      dragStart(ev: TouchEvent) {
         // ev.preventDefault();
         if ((this.images?.length ?? 1) > 1) {
            this.startX = ev.touches[0].clientX;
            this.startY = ev.touches[0].clientY;
         }
      },
      dragMove(ev: TouchEvent) {
         if (this.startX === -1) {
            return;
         }
         this.dragDistance = ev.touches[0].clientX - this.startX;
         this.speed = ev.touches[0].clientX - this.lastX;
         const speedY = ev.touches[0].clientY - this.lastY;
         this.lastX = ev.touches[0].clientX;
         this.lastY = ev.touches[0].clientY;

         if (Math.abs(this.dragDistance) < 20 && Math.abs(this.speed) < Math.abs(speedY)) {
            // scrolled more vertically than horizontally
            this.dragDistance = 0;
            this.startX = -1;
            return;
         }

         if (Math.abs(this.dragDistance) > 10) {
            this.dragging = true;
         }
         if (this.dragging) {
            ev.preventDefault();
         }
      },
      dragEnd(ev: TouchEvent) {
         if (!this.dragging) {
            this.dragDistance = 0;
            return;
         }

         this.dragging = false;
         ev.preventDefault();

         if ((this.speed < -10 || this.dragDistance < -this.getWidth() / 2) && this.slide < this.images!.length) {
            ++this.slide;
         } else if ((this.speed > 10 || this.dragDistance > this.getWidth() / 2) && this.slide > 1) {
            --this.slide;
         }
         this.dragDistance = 0;
      },
      getWidth(): number {
         return this.slideElement?.offsetWidth ?? 0;
      }
   },
   computed: {
      sliderWidth(): number {
         return this.slideElement?.offsetWidth ?? 0;
      },

      containerStyle(): {[key: string]: string | number } {
         return {
            width: `calc(100% * ${this.images?.length})`,
            transform: `translate3d(calc((-100% / ${this.images?.length} * ${this.slide - 1}) + ${this.dragDistance}px), 0, 0)`,
            transition: this.dragDistance ? '' : 'transform .1s ease-out',
            height: '100%'
         };
      }
   }
});
