import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5eab0741"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "dots"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "slider",
    ref: el => _ctx.slideElement = el
  }, [
    _createElementVNode("div", {
      class: "slide-container",
      style: _normalizeStyle(_ctx.containerStyle),
      onTouchstart: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.dragStart && _ctx.dragStart(...args))),
      onTouchmove: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dragMove && _ctx.dragMove(...args))),
      onTouchend: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.dragEnd && _ctx.dragEnd(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: "slide",
          style: _normalizeStyle({'background-image' : `url(${image})`})
        }, null, 4))
      }), 128))
    ], 36),
    (_ctx.images.length > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, i) => {
            return (_openBlock(), _createElementBlock("i", {
              key: i,
              class: _normalizeClass(["fa fa-circle text-primary", {active: i + 1 === _ctx.slide}])
            }, null, 2))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 512))
}