
import router from '@/router';
import { defineComponent } from 'vue';
import Slider from './Slider.vue';
import Stars from './Stars.vue';

export default defineComponent({
   components: { Slider, Stars },
   name: 'Shoe',
   props: {
      shoe: Object,
      wide: Boolean
   },
   data: () => ({
      apiUrl: process.env.VUE_APP_API_URL
   }),

   methods: {
      open() {
         if (typeof this.shoe?.id === 'undefined') {
            return;
         }
         router.push({ name: 'detail', params: { id: this.shoe.id } });
      }
   }
});
