import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Overview from '../views/Overview.vue';

const routes: Array<RouteRecordRaw> = [
   {
      path: '/',
      name: 'Overview',
      component: Overview
   },
   {
      path: '/detail/:id',
      name: 'detail',
      component: () => import(/* webpackChunkName: "detail" */ '../views/Detail.vue')
   },
   {
      path: '/add',
      name: 'add',
      component: () => import(/* webpackChunkName: "add" */ '../views/Edit.vue')
   },
   {
      path: '/edit/:id',
      name: 'edit',
      component: () => import(/* webpackChunkName: "add" */ '../views/Edit.vue')
   }
];

const router = createRouter({
   history: createWebHashHistory(),
   routes
});

export default router;
