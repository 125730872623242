export async function api(url: string, method: 'GET' | 'POST' | 'PATCH' | 'DELETE' = 'GET', data: {[key: string]: any} | FormData = {}) {
   let token = localStorage.getItem('token');
   if (!token) {
      return;
   }
   let config: RequestInit = {
      method,
      headers: {
         'Authorization': `Bearer ${token}`
      }
   };

   if (method !== 'GET') {
      config.body = data instanceof FormData ? data : JSON.stringify(data);
         
      if (!(data instanceof FormData)) {
         config.headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         };
      }
   }

   const response = await fetch(url, config);

   if (response.status === 403) {
      localStorage.removeItem('token');
   }
   
   const json = await response.json();
   if ((json.success ?? true) === false) {
      localStorage.removeItem('token');
   }
   
   return json;
}